import React from "react";
import { Space, Row, Tag, Typography, Col } from "antd";
import { IPayoutRequest } from "../../helpers/types";
import dayjs from "dayjs";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";
import { useTranslation } from "react-i18next";

type props = {
  list?: IPayoutRequest[];
  sentryRef: any;
  loading: boolean;
  hasMore: boolean;
};

const CreatorPaymentHistoryPaymentTabComponent: React.FC<props> = ({ list, sentryRef, loading, hasMore }) => {
  const { t } = useTranslation();

  return (
    <>
      {list === undefined || list.length === 0 ? (
        <Col className="not-payment-text">{t("no-payment-has-been-made")}</Col>
      ) : (
        <div className="payment-container">
          {list &&
            list.map((item, index) => {
              return (
                <Row className="payment-row" key={index}>
                  <Space>
                    <Tag className="tag">{item.refId}</Tag>
                    <Typography.Text className="font-12-regular text-grey-color">{dayjs(item.createdAt).format("DD MMMM YYYY")}</Typography.Text>
                  </Space>
                  <Typography.Text className="font-13-bold text-white-color">
                    {item.netprice?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    €
                  </Typography.Text>
                </Row>
              );
            })}
          {(loading || hasMore) && <SpinnerComponent refProp={sentryRef} />}
        </div>
      )}
    </>
  );
};

export default CreatorPaymentHistoryPaymentTabComponent;
